@import 'nprogress/nprogress.css';
@import '@arco-themes/react-arco-pro/index.less';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(
      304.17deg,
      rgb(253 254 255 / 60%) -6.04%,
      rgb(244 247 252 / 60%) 85.2%
    ) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(
        304.17deg,
        rgba(90, 92, 95, 0.6) -6.04%,
        rgba(87, 87, 87, 0.6) 85.2%
      ) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}
.arco-form-label-item-left {
  min-width: 80px;
}
.search-double-rows-wrapper {
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
  width: auto !important;
  .search-double-row {
    // margin-right: 20px !important;
    width: 280px !important;
  }
  .search-double-row-long{
    width: 345px !important;
  }
}
.search-row {
  // margin-right: 20px !important;
  width: 280px !important;
}
