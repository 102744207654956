@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  width: 100%;
  height: 100%;
}

.layout-navbar {
  position: fixed;
  width: 100%;
  min-width: @layout-max-width;
  top: 0;
  left: 0;
  height: @nav-size-height;
  z-index: 100;

  &-hidden {
    height: 0;
  }
}

.layout-sider {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--color-text-4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text-3);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    background-color: var(--color-border);
  }

  > :global(.arco-layout-sider-children) {
    overflow-y: hidden;
  }

  .collapse-btn {
    height: 24px;
    width: 24px;
    background-color: var(--color-fill-1);
    color: var(--color-text-3);
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // 位置
    position: absolute;
    bottom: 12px;
    right: 12px;

    &:hover {
      background-color: var(--color-fill-3);
    }
  }
}

.menu-wrapper {
  overflow: auto;
  height: 100%;

  :global(.arco-menu-item-inner > a::after),
  :global(.arco-menu-item > a::after) {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  :global(.arco-menu-inline-header) {
    font-weight: 500;
  }
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.icon-empty {
  width: 12px;
  height: 18px;
  display: inline-block;
}
@media (max-width: 600px) {
  .layout-content {
    max-width:auto !important;
  }
}
.layout-content {
  background-color: var(--color-fill-2);
  min-width: @layout-max-width;
  min-height: 100vh;
  transition: padding-left 0.2s;
  box-sizing: border-box;
}
.mobile_layout{
  min-width: auto !important;
}
.layout-content-wrapper {
  padding: 16px 20px 0px 20px;
}

.layout-breadcrumb {
  margin-bottom: 16px;
}

.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - @nav-size-height);
}
